<template>
	<div class="my-container p-all-20">

		<div class="top-box d-space-column">
			<!-- 头部背景 -->
			<div class="top-border-bg max-bg"></div>
			<!-- 侧边 -->
			<div class="side-border p-all-10">
			</div>
			<div class="pledge-box max-bg p-all m-bottom">
				<div class="title-box d-center-row">
					<img src="../../assets/image/title-icon-1.png" alt="">
					<span class="m-left-right f-w">{{ i18n.blind }}</span>
					<img src="../../assets/image/title-icon-2.png" alt="">
				</div>

				<div class="top-box-bg max-bg">
					<div class="blind-box">
						<div class="inside-box d-center-row">
							<div class="box-bg max-bg d-center-column">
								<div class="box-border max-bg"></div>
								<div class="box-border max-bg"></div>
								<div class="box-top max-bg"
									:style="{ opacity: (isShowCard ? '0' : ''), transition: `${isShowCard ? 'all linear 2s' : 'none'}` }">
								</div>
								<div v-if="isShowLight && !isShowMask" class="show-box d-space-column p-all">
									<div class="show-card max-bg">
									</div>
								</div>
							</div>
							<div class="light-1 max-bg"
								:style="{ opacity: (isShowLight ? '1' : '0'), transition: `${isShowCard ? 'all linear 2s' : 'none'}` }">
							</div>
							<div class="light-2 max-bg"
								:style="{ opacity: (isShowLight ? '1' : '0'), transition: `${isShowCard ? 'all linear 2s' : 'none'}` }">
							</div>
							<template v-if="isShowMask">
								<div class="the-reward max-bg d-space-column align-start" v-if="drawInfo.amount > 0">
									<span>{{ i18n.get }}</span>
									<span>{{ drawInfo.amount }} ETH</span>
								</div>
								<div class="the-reward max-bg d-space-column align-start" v-else>
									<span>{{ i18n.no }}</span>
								</div>
								<div class="close-icon" @click="closeAll">
									<img src="../../assets/image/close.png" alt="">
								</div>
							</template>
						</div>
					</div>
				</div>
				<div class="buy-btn max-bg d-center-row" @click="openBlindBox">
					<span>{{ parseFloat(price).toFixed(2) }} LELE <span>{{ i18n.open }}</span></span>
				</div>
			</div>
			<div class="bottom-border max-bg"></div>
			<div class="record-box">
				<div class="d-space-row">
					<span>{{ i18n.records }}</span>
				</div>
				<div class="list-box p-all-20 m-top">
					<div class="stat-list">
						<van-list v-model="loading" :finished="finished" :loading-text="$t('app.loading')"
							:finished-text="$t('app.finished')" @load="loadMore()">
							<template v-for="(item, index) in list">
								<div class="m-bottom">
									<myDrawItem :item="item" :key="item.id"></myDrawItem>
								</div>
							</template>
						</van-list>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
import { info, openBox, list } from "@/api/draw.js";
import { accountInfo } from "@/api/account.js";
import anime from "animejs";
import myDrawItem from '../components/myDrawItem.vue';
export default {
	components: {
		myDrawItem
	},
	data() {
		return {
			drawInfo: {},
			userInfo: {},
			price: '',
			compoundList: [{
				target: ".light-1",
				duration: 2000,
				direction: 'alternate',
				rotate: "-10deg",
				loop: true,
			},
			{
				target: ".light-2",
				duration: 2000,
				direction: 'alternate',
				rotate: "30deg",
				loop: true,
			},
			],

			isShowCard: false,
			isShowLight: false,
			isShowMask: false,

			list: [],
			pageNum: 1,
			pageSize: 10,
			loading: true,
			finished: false,
		};
	},
	computed: {
		i18n() {
			return this.$t('draw')
		}
	},
	created() {
		this.$dapp.useAddress(() => {
			this.$nextTick(() => {
				this.getInfo();
				this.getUserInfo();
				this.getList()
			})
		})


	},
	methods: {
		async getUserInfo() {
			const { err, res } = await accountInfo();
			this.userInfo = res;
		},
		async getInfo() {
			const { err, res } = await info();
			this.price = res.price;
		},
		async openBlindBox() {
			this.isShowCard = false
			this.isShowLight = false
			this.isShowMask = false
			await this.$dapp.onConnect();
			const data = {
				address: this.userInfo.address,
				deadline: parseInt(Date.now() / 1000 + 60).toString(),
			};
			const [error, sign] = await this.$dapp.signMessage(data);
			if (error === null) {
				const { err, res } = await openBox({
					sign,
					deadline: Number(data.deadline),
				});
				if (err === null) {
					// this.$toast(this.$t("app.opeSuccess"));
					// this.getList()
					// this.getBalance()
					this.drawInfo = res
					this.isShowCard = true
					setTimeout(() => {
						this.isShowLight = true
						setTimeout(() => {
							this.compoundEffect()
							this.showCardEffect()
						}, 100)
					}, 500)
					this.pageNum = 1
					this.list = []
					this.getList()

				}
			}


		},

		async getList() {

			const { err, res } = await list({ pageNum: this.pageNum, pageSize: this.pageSize });
			if (err === null) {
				this.list = this.list.concat(res.records);
				let length = res.records.length
				if (length < this.pageSize) {
					this.finished = true;
				} else {
					this.loading = false
				}
			}

		},
		// 合成后的特效
		compoundEffect() {
			this.compoundList.forEach((item) => {
				anime({
					targets: item.target,
					rotate: item.rotate,
					direction: item.direction,
					duration: item.duration,
					easing: "linear",
					loop: item.loop,
				});
			});
		},
		showCardEffect() {
			anime({
				targets: '.show-card',
				translateY: '-60px',
				opacity: 1,
				duration: 3000,
				scale: 2,
				easing: "linear",
				rotateY: "360deg",
				translateZ: "1px",
				complete: (res) => {
					this.isShowMask = true
				},
			});
		},
		closeAll() {
			this.pageNum = 1
			this.list = []
			this.getList()
			this.isShowCard = false
			this.isShowLight = false
			this.isShowMask = false
		},
		loadMore() {
			this.pageNum++
			this.getList()
		}
	}

}
</script>

<style lang="scss" scoped>
.my-container {
	.top-box {
		width: 100%;
		// height: auto;
		position: relative;
		box-sizing: border-box;
		padding-top: 50px;

		.top-border-bg {
			width: 100%;
			height: 300px;
			background: url('../../assets/image/border-bg.png');
			position: absolute;
			left: 0;
			top: 0;
		}

		.side-border {
			width: 100%;
			height: 1350px;
			border-left: 2px solid #4BB005;
			border-right: 2px solid #4BB005;
			position: absolute;
			left: 0;
			bottom: 0;
		}

		.pledge-box {
			width: 643px;
			height: 750px;
			background: url('../../assets/image/info-bg.png');
			position: relative;
			box-sizing: border-box;
			padding-top: 60px;

			.title-box {
				width: 643px;
				height: 50px;
				// border: 1px solid red;
				position: absolute;
				top: -26px;
				left: 0;

				img {
					width: 54px;
					height: 30px;
				}

				font-size: 42px;
			}

			.buy-btn {
				width: 565px;
				height: 77px;
				background: url('../../assets/image/btn-bg.png');
				margin: 0 auto;
				font-size: 30rpx;

				span {
					color: #EFCE28;
					margin-right: 10rpx;

					span {
						color: #fff;
					}
				}
			}

			.top-box-bg {
				width: 535px;
				height: 550px;
				background: url('../../assets/image/blind-box-bg.png');
				position: relative;
				margin: 0 auto;

				.blind-box {
					position: absolute;
					width: 100%;
					height: 500px;
					bottom: 0;

					.inside-box {
						width: 100%;
						height: 100%;
						position: relative;

						.box-bg {
							width: 265px;
							height: 249px;
							background: url('../../assets/image/box-bg.png');
							position: relative;
							z-index: 99;
							justify-content: flex-start;

							.box-border {
								width: 282px;
								height: 275px;
								background: url('../../assets/image/box-border.png');
								position: absolute;
								top: -16px;
								left: -10px;
								z-index: 7;
							}

							.box-top {
								width: 259px;
								height: 46px;
								background: url('../../assets/image/box-top.png');
								position: absolute;
								top: 0;
								left: 0;
								z-index: 6;
							}

							.show-box {
								width: 248px;
								height: 136px;
								position: relative;

								.show-card {
									width: 124px;
									height: 68px;
									background: url('../../assets/image/blind-card.png');
									position: absolute;
									z-index: 99;
									top: 0px;
									opacity: 0;
									font-size: 30px;

									span:nth-child(2) {
										color: #74f800;
									}
								}
							}

						}

						.light-1 {
							width: 707px;
							height: 339px;
							background: url('../../assets/image/light-1.png');
							position: absolute;
							left: -130px;
							top: -150px;
							z-index: 9;
							transform-origin: center bottom;
						}

						.light-2 {
							width: 707px;
							height: 339px;
							background: url('../../assets/image/light-2.png');
							position: absolute;
							left: -130px;
							top: -150px;
							z-index: 9;
							transform-origin: center bottom;
						}

						.the-reward {
							width: 248px;
							height: 136px;
							box-sizing: border-box;
							padding: 30px 20px;
							background: url('../../assets/image/blind-card.png');
							position: absolute;
							z-index: 999;
							top: -30px;
							font-size: 20px;

							span:nth-child(2) {
								font-size: 30px;
								color: #74f800;
							}
						}

						.close-icon {
							position: absolute;
							right: 100px;
							top: -80px;
							z-index: 99;

							img {
								width: 50px;
								height: 50px;
							}
						}
					}
				}
			}
		}

		.record-box {
			width: 100%;
			border-left: 2px solid #4BB005;
			border-right: 2px solid #4BB005;
			box-sizing: border-box;
			padding: 30px 20px;

			.list-box {
				width: 100%;
				background: rgba(82, 121, 42, 0.4);
				box-shadow: 0px 0px 13px 0px rgba(65, 126, 150, 0.25);
				border-radius: 12px;

				.stat-list {
					border-radius: 8px;
					position: relative;

					.van-list {
						width: 100%;
						height: calc(100vh - 760px);
						overflow: scroll;
					}
				}
			}
		}

		.bottom-border {
			width: 100%;
			height: 2px;
			background: url('../../assets/image/bottom-border.png');
		}
	}
}
</style>