import request from '@/utils/request'

//首页数据
export const  info  = () => request.get('draw/info')

//开启盲盒
export const  openBox  = (data) => request.post('draw/openBox',data)

//记录
export const  list  = (data) => request.get('draw/list',data)




