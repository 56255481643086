<template>
	<div class="item-container">
		<div class="team-item d-space-row">
			<div class="d-space-column align-start">
				<span class="f-20 time">{{ item.createTime }}</span>
				<!-- <span> - {{item.arcAmount}}</span> -->
			</div>
			<div class="right">
				<span class="f-30" v-if="item.ethAmount > 0">+ {{parseFloat(item.ethAmount).toFixed(6)}} ETH</span>
				<span class="f-30" v-else> {{i18n.no}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
			},
			time:{
				type:String,
				default:''
			},
			label:{
				type:String,
				default:''
			},
			value:{
				type:String,
				default:''
			},
		},
		data() {
			return {};
		},
		computed: {
			i18n() {
				return this.$t('draw')
			}
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.item-container {
		width: 100%;
		.time{
			color: #86B871;
		}
		.right {
			color: #F0FF6E;
		}
	}
</style>